import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

export default ({ post: { title, photo }, slug }) => {
  return (
    <Link to={slug} style={styles.linkContainer}>
      <Image fixed={photo.childImageSharp.fixed} />
    </Link>
  )
}

const styles = {
  linkContainer: {
    boxShadow: "0 0",
    display: "flex",
    marginTop: 14,
    marginBottom: 14,
  },
}
