import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PhotoLink from "../components/links/photo-link"

class PhotosIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const photos = data.photoMetadata.edges

    return (
      <Layout location={this.props.location} title={siteTitle + ": photos"}>
        <SEO
          title="Photos"
          keywords={[
            `blog`,
            `photos`,
            `vanlife`,
            `sharnik`,
            `wojciech ogrodowczyk`,
            `haiku code`,
            `travel`,
            `street`,
            `landscapes`,
          ]}
        />
        <Bio />
        <div style={styles.photosContainer}>
          {photos.map(({ node }) => (
            <PhotoLink post={node.frontmatter} slug={node.fields.slug} />
          ))}
        </div>
      </Layout>
    )
  }
}

const styles = {
  photosContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    width: 935,
    marginLeft: -125,
    justifyContent: "space-between",
  },
}
export default PhotosIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    photoMetadata: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/photos//" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            photo {
              childImageSharp {
                fixed(width: 293, height: 293, cropFocus: CENTER, quality: 80) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            title
            description
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
